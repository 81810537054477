<template>
  <div
    class="the-navbar__user-meta flex items-center"
    v-if="activeUserInfo.displayName"
  >
    <div class="text-right leading-tight hidden sm:block mr-8 ml-8">
      <p style="cursor: pointer" class="font-semibold">{{ language }}</p>
      <!--      <p @click="onClickLanguage" style="cursor: pointer" class="font-semibold">{{language}}</p>-->
    </div>
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ displayName }}</p>
      <small>Online</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <img
          v-if="activeUserInfo.photoURL"
          key="onlineImg"
          src="https://smartfilebox.com/static/misago/img/blank-avatar.png"
          alt="user-img"
          width="40"
          height="40"
          class="rounded-full shadow-md cursor-pointer block"
        />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">
          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
          >
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Profile</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout"
          >
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Logout</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
import store from '@/store/store.js';
import Cookies from 'js-cookie';
import { mapActions } from 'vuex';

export default {
  data() {
    return {
      displayName: '',
      language: store.getters.currentLanguage,
    };
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
  methods: {
    ...mapActions(['setLanguage']),
    logout() {
      localStorage.removeItem('userInfo');
      Cookies.remove('current_user_token');
      // This is just for demo Purpose. If user clicks on logout -> redirect
      window.location.href = '/pages/login';
    },
    async onClickLanguage() {
      const vue = this;
      vue.$vs.loading();
      await setTimeout(async function () {
        if (store.getters.currentLanguage === 'EST') {
          await vue.setLanguage('RUS');
          vue.language = 'RUS';
        } else {
          await vue.setLanguage('EST');
          vue.language = 'EST';
        }
        vue.$vs.loading.close();
      }, 1000);
    },
  },
  mounted() {
    this.displayName = store.getters.currentUser.username;
  },
};
</script>

/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: '/',
    name: 'Dashboard',
    slug: 'home',
    icon: 'HomeIcon',
  },
  {
    header: 'PRODUCTS',
    icon: 'ArchiveIcon',
    items: [
      {
        url: '/node-manage',
        name: 'Groups',
        slug: 'home',
        icon: 'CommandIcon',
      },
      {
        url: '/products',
        name: 'Product Items',
        slug: 'products',
        icon: 'BoxIcon',
      },
      {
        url: '/classifiers',
        name: 'Classifiers',
        slug: 'classifiers-type',
        icon: 'BookOpenIcon',
      },
      // {
      //   url: '/price-changes',
      //   name: 'Manufacturer Price Change',
      //   slug: 'home',
      //   icon: 'PercentIcon',
      // },
      // {
      //   url: '/product-recalls',
      //   name: 'Product Recalls',
      //   slug: 'product-recalls',
      //   icon: 'TriangleIcon',
      // },
      // {
      //   url: '/product-relations',
      //   name: 'Product Relations',
      //   slug: 'product-relations',
      //   icon: 'CpuIcon',
      // },
      // {
      //   url: '/meddec',
      //   name: 'MEDDEC',
      //   slug: 'meddec',
      //   icon: 'HeartIcon',
      // },
    ],
  },
  // {
  //   header: 'OTHER',
  //   icon: 'ArchiveIcon',
  //   items: [
  //     {
  //       url: '/document-approval',
  //       name: 'Document Approval Recalls',
  //       slug: 'document-approval',
  //       icon: 'BookIcon',
  //     },
  //     {
  //       url: '/rimi-points',
  //       name: 'Rimi Points',
  //       slug: 'rimi-points',
  //       icon: 'ArchiveIcon',
  //     },
  //     {
  //       url: '/gift-cards',
  //       name: 'Gift Cards',
  //       slug: 'gift-cards',
  //       icon: 'GiftIcon',
  //     },
  //   ],
  // },
];
